import { Box, Button, Flex, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { initializeChakraComponent } from 'Shared/chakra-initialize';

export function HomePageCtas(props: CtaProps) {
   return (
      <Flex
         direction="column"
         mx="6"
         my={{ base: 12, lg: 16 }}
         alignItems="center"
         textAlign="center"
      >
         <Heading m="0" fontSize="4xl">
            {_js('Never take broken for an answer')}
         </Heading>
         <Text mt="6" mb={{ base: 12, lg: 16 }} fontSize="lg">
            {_js(
               'Get the instructions you need with quality repair parts and tools and the expertise of a robust community.'
            )}
         </Text>
         <CtaGrid {...props} />
      </Flex>
   );
}

interface CtaProps {
   imagePath: string;
   title: string;
   subtitle: string;
   href: string;
   buttonText: string;
   altText: string;
}

const CtaGrid = (props: CtaProps) => (
   <SimpleGrid maxWidth="1216px" spacing={6} columns={{ lg: 3 }}>
      {Object.values(props).map((props: CtaProps, index: number) => (
         <Cta key={index} {...props} />
      ))}
   </SimpleGrid>
);

const Cta = ({ imagePath, title, subtitle, href, buttonText, altText }: CtaProps) => (
   <Box
      maxWidth="538px"
      display="flex"
      borderRadius="md"
      boxShadow="md"
      bgColor="white"
      overflow="hidden"
   >
      <Flex direction="column" alignItems="center" pb={6} width="100%">
         <Flex as="picture" justify="center" overflow="hidden">
            <source
               srcSet={`
                  ${imagePath}-mobile.avif 425w,
                  ${imagePath}-desktop.avif 538w
               `}
               type="image/avif"
            />
            <Image
               src={`${imagePath}-desktop.jpg`}
               srcSet={`${imagePath}-mobile.jpg 425w, ${imagePath}-desktop.jpg 538w`}
               htmlWidth="538"
               htmlHeight="200"
               objectFit="cover"
               maxWidth={{ base: '425px', sm: '538px' }}
               width="100%"
               alt={altText}
            />
         </Flex>
         <Stack spacing={6} mt={6} px={5}>
            <Text m={0} color="gray.900" fontSize="2xl" fontWeight="semibold">
               {title}
            </Text>
            <Text m={0} fontSize="lg">
               {subtitle}
            </Text>
         </Stack>
         <Box w="100%" maxWidth="388px" px={5} mt="auto">
            <Button as="a" href={href} variant="cta" fontSize="lg" w="100%" mt={6}>
               {buttonText}
            </Button>
         </Box>
      </Flex>
   </Box>
);

initializeChakraComponent('HomePageCtas', HomePageCtas);
